import React from 'react';
import './WarningModal.css';
import { Modal, Box, Button } from '@mui/material';

const WarningModal = ({ open, onClose, isWarningModalMessage }) => {
  if (!open) return null;

  return (
    // <div className="modal-overlay">
    //   <div className="modal-content">
    //     <h2>Warning</h2>
    //     <p>{isWarningModalMessage}</p>
    //     <button onClick={onClose}>Close</button>
    //   </div>
    // </div>
    <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box className="modal-overlay" >
      <div className="modal-content">
      <h2>Warning</h2>
        <p>{isWarningModalMessage}</p>
      </div>
      <button className='closeCaptureModal' onClick={onClose}>Close</button>
    </Box>
  </Modal>
  );
};

export default WarningModal;
