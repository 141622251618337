import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Styles/LpaStoreAnalysisStyles.css';
import WarningModal from '../LPAStoreAnalysis/WarningModal/WarningModal';

const PastAuditFindingLogs = () => {
    const { auditCode } = useParams();
    const [auditData, setAuditData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAuditData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await axios.get('https://myworxit.co.za/api/audits/viewPastAuditFindings.php', {
                    params: { auditCode }
                });
                
                setAuditData(response.data);
            } catch (error) {
                setError("Error fetching audit data.");
                console.error("API error:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAuditData();
    }, [auditCode]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handleBackClick = () => {
        window.history.back();
    }

    return (
        <div className="findingLogsContainer">
            <div className='titleHeader'>
                <h4>Findings</h4>
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="responsesColWidth">Questions/ Responses</th>
                        <th className="capturedImageColWidth">Captured Images</th>
                        <th className="actionColWidth">Action</th>
                        <th className="responPersColWidth">Responsible Person</th>
                        <th className="dueDateColWidth">Due Date</th>
                    </tr>
                </thead>
                <tbody>
                    {auditData && auditData.length > 0 ? (
                        auditData.map((details, index) => (
                            <tr key={index}>
                                <td className="responsesColWidth">
                                    {details.questions} - {details.responses}
                                </td>
                                {details.capturedImages ? (
                                    <td className="capturedImageColWidth">
                                        <img style={{ width: '116px' }} src={details.capturedImages} alt='' />
                                    </td>
                                ) : (
                                    <td className="capturedImageColWidth">
                                        <p>No Image Captured</p>
                                    </td>
                                )}
                                <td className="actionColWidth">
                                    {details.actions}
                                </td>
                                <td className="responPersColWidth">
                                    {details.responsPerson}
                                </td>
                                <td className="dueDateColWidth">
                                    {details.dueDate}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="1" className='noFindingsText'>No findings found for the provided audit code.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className='submitAuditContainer'>
                <button className='submitAuditBtn' onClick={handleBackClick}>Back</button>
            </div>
        </div>
    );
};

export default PastAuditFindingLogs;
