import {
	Container,
	Button,
	Grid,
	Paper,
	TextField,
	IconButton,
	InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.css";
import { useSetRecoilState } from "recoil";
import { auditHeaderDataAtom } from '../../Recoil/auditHeaderDataAtom';
import ClipLoader from "react-spinners/ClipLoader"; // Import ClipLoader

const Login = () => {

	const navigate = useNavigate();

    const setAuditHeaderData = useSetRecoilState(auditHeaderDataAtom)
	const [message, setMessage] = useState("");
	const [values, setValues] = useState({
		username: "",
		password: "",
		site:"",
		showPass: false,
	});
    const [isLoading, setIsLoading] = useState(false); // Add loading state

	const handleSubmit = async (e) => {
		e.preventDefault();

				// Check for internet connection
				if (!navigator.onLine) {
					alert('No Internet connection');
					return;
				}

		setIsLoading(true); // Set loading state to true when form is submitted

		// // Check if geolocation is available in the browser
		// if ('geolocation' in navigator) {
		// 	// Use the Geolocation API to get the user's current position
		// 	navigator.geolocation.getCurrentPosition(
		// 		(position) => {
					// Extract latitude and longitude from the position object
					// const { latitude, longitude } = position.coords;

					axios
						.post("https://myworxit.co.za/api/audits/users.php", {
							username: values.username,
							password: values.password,
						})
						.then((res) => {
							if (res.data.Status === '200') {
								setAuditHeaderData(oldHeaderData => [
									...oldHeaderData,
									{
										user: values.username,
										//site: values.site,
										// latitude,
										// longitude
									},
								]);
								navigate('/home');
							} else {
								setMessage('User Does Not Exist!');
							}
						})
						.catch((err) => console.error(err))
						.finally(() => setIsLoading(false)); // Set loading state to false after request completes
				//},
			// 	(error) => {
			// 		console.error('Error getting location:', error);
			// 		setIsLoading(false); // Set loading state to false if geolocation fails
			// 	}
			// );
		// } else {
		// 	// Geolocation is not available in the browser, handle accordingly
		// 	console.error('Geolocation is not available in this browser.');
		// 	// Rest of the function logic (without latitude and longitude handling)
		// 	setIsLoading(false); // Set loading state to false if geolocation is not available
		// }
	};

	const handlePassVisibilty = () => {
		setValues({
			...values,
			showPass: !values.showPass,
		});
	};

	return (
		<div className="login-form">
			<Container maxWidth="sm">
				<Grid
					container
					spacing={2}
					direction="column"
					justifyContent="center"
					style={{ minHeight: "60vh" }}
				>
					<Paper elevation={2} sx={{ padding: 5 }}>
						<form onSubmit={handleSubmit}>
							<Grid container direction="column" spacing={2}>
								<Grid item>
									<TextField
										type="text"
										fullWidth
										label="Enter your username"
										placeholder="Username"
										variant="outlined"
										required
										onChange={(e) => setValues({ ...values, username: e.target.value })}
									/>
								</Grid>
								<Grid item>
									<TextField
										type={values.showPass ? "text" : "password"}
										fullWidth
										label="Password"
										placeholder="Password"
										variant="outlined"
										required
										onChange={(e) => setValues({ ...values, password: e.target.value })}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={handlePassVisibilty}
														aria-label="toggle password"
														edge="end"
													>
														{values.showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								{/* <Grid item>
									<TextField
										type="text"
										fullWidth
										label="Enter your Site Name/Number"
										placeholder="Site Name/Number"
										variant="outlined"
										required
										onChange={(e) => setValues({ ...values, site: e.target.value })}
									/>
								</Grid> */}
								<Grid item>
									<Button type="submit" fullWidth variant="contained" disabled={isLoading}>
										{isLoading ? <ClipLoader size={24} color="#ffffff" /> : "Sign In"}
									</Button>
									{message && <p className="errorMsg">{message}</p>}
								</Grid>
							</Grid>
						</form>
					</Paper>
				</Grid>
			</Container>
		</div>
	);
};

export default Login;
