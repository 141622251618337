import React,{useState, useEffect ,useCallback} from 'react';
import axios from 'axios';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useNavigate } from "react-router-dom";
import { useRecoilState,useRecoilValue,useSetRecoilState } from 'recoil';
import "../../../Styles/LpaStoreAnalysisStyles.css"
import {lpaCashOfficeQuestionsState} from '../../../Recoil/LpaCashOfficeQuestionsState';
import { lpaCashOfficeDataState } from '../../../Recoil/LpaCashOfficeDataState'
import { auditCodeAtom } from '../../../Recoil/auditCodeAtom';

import { lpaFrontlineDataState } from '../../../Recoil/LpaFrontLineDataState';
import { lpaSecurityDataState } from '../../../Recoil/LpaSecurityDataState';
import { lpaManagementDataState } from '../../../Recoil/LpaManagementDataState';
import WebcamCapture from '../../WebcamCapture/WebcamCapture';
import cameraIcon from '../../Media/cameraIcon.jpg'
import { v4 as uuidv4 } from 'uuid';
import WarningModal from '../WarningModal/WarningModal';

const LpaCashOffice = () => {

    const lpaCashOfficeQuestions = useRecoilValue(lpaCashOfficeQuestionsState);
    const [lpaData, setLpaData] = useRecoilState(lpaCashOfficeQuestionsState)
    const lpaCashOfficeData = useRecoilValue(lpaCashOfficeDataState);
    const setLpaCashOfficeData = useSetRecoilState(lpaCashOfficeDataState);
    const { responses, notes, scores, capturedImages } = lpaCashOfficeData;
    const [tempResponses, setTempResponses] = useState(responses);
    const [tempNotes, setTempNotes] = useState(notes);
    const [tempScores, setTempScores] = useState(scores)
    const [tempCapturedImages, setTempCapturedImages] = useState(capturedImages);
    const [totalScore, setTotalScore] = useState(100);
    const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);
    const auditCode = useRecoilValue(auditCodeAtom);
    const auditCodeValue = auditCode.auditCode;
    const quesId = useRecoilValue(lpaCashOfficeQuestionsState);
    const ques = useRecoilValue(lpaCashOfficeQuestionsState);

    const quesIdValues = quesId?.LPAQuestions?.map((question) => question.quesId) || [];
    const quesValues = ques?.LPAQuestions?.map((question) => question.questions) || [];

    const section = 'G';
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [isWarningModalMessage, setIsWarningModalMessage] = useState(false);
  

    //--------TEST --------------------
    const lpaDataFrontline = useRecoilValue(lpaFrontlineDataState);
    const lpaDataManagement = useRecoilValue(lpaManagementDataState);
    const lpaDataSecurity = useRecoilValue(lpaSecurityDataState);
    const [isCollapsed, setIsCollapsed] = useState(false);
   
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
      

  useEffect(() => {
      // Fetch data from the API and update LPAQuestions in the atom
      const fetchData = async () => {
        try {
          const response = await axios.get('https://myworxit.co.za/api/audits/cashOfficeSectionQuestions.php');
          const newData = response.data.records;
        
          // Update the LPAQuestions in the atom
          setLpaData((prevLpaData) => ({
            ...prevLpaData,
            LPAQuestions: newData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, [setLpaData]);

    
//     useEffect(() => {
//       const areAllRowsComplete = lpaCashOfficeQuestions.LPAQuestions.every((question, index) => {
//         const score = tempScores[index];
    
//         // Check if response and score are filled
//         if (!(score && score.trim() !== '')) {
//           return false;
//         }
    
//         // Additional check if score is Fail
//         if (score === 'Fail') {
//           // Check if capturedImages and notes are filled
//           if (!tempResponses[index] || tempResponses[index].trim() === '') {
//             return false;
//           }
//         }
    
//         return true;
//       });
//   // Check other atom states
//   const areOtherAtomsFilled = lpaDataFrontline.scores.length > 0 &&
//                               lpaDataSecurity.scores.length > 0 &&
//                               lpaDataManagement.scores.length > 0;

                              

//   setIsUpdateEnabled(areOtherAtomsFilled && areAllRowsComplete);
// }, [
//   tempResponses,
//   tempScores,
//   lpaCashOfficeQuestions.LPAQuestions.length,
//   lpaDataFrontline.scores.length,
//   lpaDataSecurity.scores.length,
//   lpaDataManagement.scores.length,
//   lpaCashOfficeQuestions.LPAQuestions,
//   tempNotes
// ]);

    
   
    //--------------CALCULATIONS--------------------
    const na = 10;
    const pass = 10;
    const fail = 0;

     // Function to calculate and update total score
     const updateTotalScore = useCallback(() => {
        const questionsCount = lpaCashOfficeQuestions.LPAQuestions.length *10;
        const naCount = tempScores.filter((score) => score === 'N/A').length;
        const naActual = na * naCount;
        const questionActual = questionsCount - naActual;
        const passCount = tempScores.filter((score) => score === 'Pass').length;
        const passActual = pass * passCount;
        let newTotalScore = (passActual / questionActual) * 100;
        newTotalScore = Math.ceil(newTotalScore); // Round the total score to the nearest whole number
        setTotalScore(newTotalScore);
        setLpaCashOfficeData((prevLpaData) => ({
            ...prevLpaData,
            totalScore: newTotalScore,
        }));
    }, [tempScores, setLpaCashOfficeData,lpaCashOfficeQuestions.LPAQuestions]);


    // useEffect to re-calculate total score whenever scores change
    useEffect(() => {
        updateTotalScore();
        }, [tempScores, updateTotalScore]);
    
        
     
      // Function to submit progress and send data to the server (or perform any other action)
      const submitProgress = async () => {
        let isScoreIncomplete = false;
        let isResponseIncomplete = false;
      
        const areAllScoresComplete = lpaCashOfficeQuestions.LPAQuestions.every((question, index) => {
          const score = tempScores[index];
          const response = tempResponses[index];
      
          // Check if score is filled
          if (!(score && score.trim() !== '')) {
            isScoreIncomplete = true;
            return false;
          }
      
          // Additional check if score is Fail and response is filled
          if (score === 'Fail' && (!response || response.trim() === '')) {
            isResponseIncomplete = true;
            return false;
          }
      
          return true;
        });
      
        if (!areAllScoresComplete) {
          setIsWarningModalOpen(true);
          if (isScoreIncomplete) {
            setIsWarningModalMessage('Please complete all the scores for every question in the above audit.');
          } else if (isResponseIncomplete) {
            setIsWarningModalMessage('Please complete responses for each failed score.');
          }
          return;
        }
      
        const dateTime = new Date().toLocaleString('en-US', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        });
      
        setTempResponses((prevResponses) => [...prevResponses]);
        setTempNotes((prevNotes) => [...prevNotes]);
      
        setLpaCashOfficeData((prevLpaData) => ({
          ...prevLpaData,
          auditCode: auditCodeValue,
          section,
          quesId: quesIdValues,
          question: quesValues,
          responses: tempResponses,
          notes: tempNotes,
          scores: tempScores,
          capturedImages: tempCapturedImages,
          dateTime,
          totalScore,
        }));
      
        const isFrontlineComplete = lpaDataFrontline?.scores?.length > 0;
        const isSecurityComplete = lpaDataSecurity?.scores?.length > 0;
        const isManagementComplete = lpaDataManagement?.scores?.length > 0;
      
        if (!isFrontlineComplete || !isSecurityComplete || !isManagementComplete) {
          if (!isFrontlineComplete) {
            alert('Please complete LPA Store Analysis: Frontline');
          }
          if (!isSecurityComplete) {
            alert('Please complete LPA Store Analysis: Security');
          }
          if (!isManagementComplete) {
            alert('Please complete LPA Store Analysis: Management');
          }
        } else {
          navigate('/home');
        }
      };
      

const toggleCollapse = () => {
  setIsCollapsed(!isCollapsed);
};

const handleWebcamCapture = (index) => {
  setCurrentQuestionIndex(index);
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
  setIsWarningModalOpen(false);
};
    const handleTakePhoto = async (dataUri) => {
      try {
        const uuid = uuidv4(); // Generate unique ID
        const formData = new FormData();
        const blob = await fetch(dataUri).then(res => res.blob());
        formData.append('file', blob, `${uuid}.jpg`);

        const response = await axios.post('https://myworxit.co.za/api/audits/captuedImages.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        const imageUrl = response.data.url;

        const newCapturedImages = [...tempCapturedImages];
        newCapturedImages[currentQuestionIndex] = imageUrl;
        setTempCapturedImages(newCapturedImages);
        closeModal();
      } catch (error) {
        console.error("Error uploading image", error);
      }
    };

     return (
      <div className={`questionContainer ${isCollapsed ? 'collapsed' : ''}`}>
      <div className='titleHeader' onClick={toggleCollapse}>
        <div className='arrowCon'>
          <span className={`arrowIcon ${isCollapsed ? 'up' : 'down'}`}></span>
        </div>
        <div className='titleCon'>
          <h4>LPA Store Analysis : Cash Office</h4>
        </div>
        
        </div>
        {!isCollapsed && ( 
        <>
              <table>
                <thead>
                    <tr>
                        {/* <th className="typeWidth">Type</th> */}
                        <th className="questionsColumnWidth">LPA Question</th>
                        <th className="answersColumnWidth">Responses</th>
                        <th className="scoreColumnWidth">Score</th>
                        <th className="notesColumnWidth">Notes </th>
                    </tr>
                </thead>
                <tbody>
                {Array.isArray(lpaCashOfficeQuestions.LPAQuestions) ? (
                lpaCashOfficeQuestions.LPAQuestions.map((question, index) => (
                            <tr key={index}>
                                {/* <td><div className='circle'></div></td> */}
                                <td className="questionsColumnWidth">{question.questions}</td>
                                <td className="answersColumnWidth">   
                                <TextareaAutosize
                                    minRows={3} // Set the minimum number of rows (initially 1 row)
                                    placeholder="Type your response here"
                                    style={{
                                    width: '100%', // Expand the textarea to fill the entire cell
                                    minHeight: '5px', // Make the textarea height match the row height
                                    border: 'none', // Remove borders for a cleaner appearance
                                    padding: 0, // Remove any padding inside the textarea
                                    resize: 'none', // Disable textarea resizing
                                    boxSizing: 'border-box', // Ensure the width and height include padding and border
                                    background: 'transparent', // Optional: Make the textarea background transparent
                                    overflow: 'hidden', // Hide any extra scrollbars
                                    }}
                                    value={tempResponses[index]} // Use the temporary responses for the text area
                                    onChange={(e) => {
                                        const newResponses = [...tempResponses]; // Use the temporary responses
                                        newResponses[index] = e.target.value; // Update the temporary response
                                        setTempResponses(newResponses); // Update the temporary responses state
                                    }}
                                  />
                                </td>
                                <td className="scoreColumnWidth">
                                  {!tempScores[index] ? (
                                    <select
                                      value={tempScores[index]}
                                      onChange={(e) => {
                                        const newScores = [...tempScores];
                                        newScores[index] = e.target.value;
                                        setTempScores(newScores);
                                      }}
                                    >
                                      <option value="">Score</option>
                                      <option className="passField" value="Pass">Pass</option>
                                      <option className="failField" value="Fail">Fail</option>
                                      <option value="N/A">N/A</option>
                                    </select>
                                  ) : (
                                    <>
                                    <span style={{ fontWeight: 'bold', color: getColorForValue(tempScores[index]), display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1, paddingTop: '5px' }}>
                                      <div className='selectedOption'>
                                        {tempScores[index]}
                                      </div>
                                      <div className='clearBtnCon'>
                                        <span
                                          className='clearBtn'
                                          onClick={() => {
                                            const newScores = [...tempScores];
                                            newScores[index] = ''; // Clear the selected value
                                            setTempScores(newScores);
                                            const newCapturedImages = [...tempCapturedImages]
                                            newCapturedImages[index] = '';
                                            setTempCapturedImages(newCapturedImages)                                          }}
                                        >
                                          Clear
                                        </span>
                                      </div>
                                    </span>
                                    <span style={{paddingBottom: '5px'}}>
                                    {tempScores[index] === 'Fail' && (
                                    <>
                                      {!tempCapturedImages[index] ? (
                                        <img
                                          style={{ width: '30px', cursor: 'pointer' }}
                                          onClick={() => handleWebcamCapture(index)}
                                          src={cameraIcon}
                                          alt='camera'
                                        />
                                      ) : (
                                        <div>
                                        <a href={tempCapturedImages[index]} target="_blank" rel="noopener noreferrer">
                                          <img
                                            src={tempCapturedImages[index]}
                                            alt="Captured"
                                            style={{ width: '50px', height: '50px', borderRadius: '5px', marginTop: '10px', cursor: 'pointer' }}
                                          />
                                        </a>
                                      </div>
                                      )}
                                    </>
                                  )}
                                  </span>
                                  </>
                                  )}
                                </td>
                                <td className="notesColumnWidth">    
                                <TextareaAutosize
                                    minRows={3} // Set the minimum number of rows (initially 1 row)
                                    placeholder="Type your notes here"
                                    style={{
                                    width: '100%', // Expand the textarea to fill the entire cell
                                    minHeight: '5px', // Make the textarea height match the row height
                                    border: 'none', // Remove borders for a cleaner appearance
                                    padding: 0, // Remove any padding inside the textarea
                                    resize: 'none', // Disable textarea resizing
                                    boxSizing: 'border-box', // Ensure the width and height include padding and border
                                    background: 'transparent', // Optional: Make the textarea background transparent
                                    overflow: 'hidden', // Hide any extra scrollbars
                                    }}
                                    value={tempNotes[index]} // Use the temporary notes for the text area
                                    onChange={(e) => {
                                        const newNotes = [...tempNotes]; // Use the temporary notes
                                        newNotes[index] = e.target.value; // Update the temporary note
                                        setTempNotes(newNotes); // Update the temporary notes state
                                    }}
                                    />
                                </td>
                               
                            </tr>
                         ))
                         ) : (
                           <tr>
                             <td colSpan="4">Loading...</td>
                           </tr>
                         )}
                    </tbody>
            </table>  
            <div className='titleFooter'>
                <h4>Cash Office LPA Score = {totalScore+"%"}</h4>
                
            </div> 
            <div className='actionBtns'>
              <div className='submitBtnCon'>
                    <button className={'submitBtn'} onClick={submitProgress} >Submit</button>
                </div>
            </div>
            </>
      )}
              <WebcamCapture isOpen={isModalOpen} onClose={closeModal} onTakePhoto={handleTakePhoto} />
              <WarningModal open={isWarningModalOpen} onClose={closeModal} isWarningModalMessage={isWarningModalMessage}/> {/* Add the WarningModal component */}

        </div>
   
    )
}

export default LpaCashOffice;

function getColorForValue(value) {
  switch (value) {
    case 'Pass':
      return 'green';
    case 'Fail':
      return 'red';
    case 'N/A':
      return 'blue';
    default:
      return 'black'; // Default color for other values
  }
}