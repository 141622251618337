import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import LpaFrontLineFeedback from '../LPAStoreFeedback/LPAStoreAnalysisFrontline/LpaFrontLineFeedback';
import LpaSecurityFeedback from '../LPAStoreFeedback/LPAStoreFeedbackSecurity/LpaSecurityFeedback';
import LpaManagementFeedback from '../LPAStoreFeedback/LPAStoreFeedbackManagement/LpaManagementFeedback';
import LpaCashOfficeFeedback from '../LPAStoreFeedback/LPAStoreFeedbackCashOffice/LpaCashOfficeFeedback';

import { auditHeaderDataAtom } from '../../Recoil/auditHeaderDataAtom';
import { auditCodeAtom } from '../../Recoil/auditCodeAtom';
import { lpaFrontlineDetailsState } from '../../Recoil/LpaFrontlineDetailsState';
import { lpaSecurityDetailsState } from '../../Recoil/LpaSecurityDetailsState';
import { lpaManagementDetailsState } from '../../Recoil/LpaManagementDetailsState';
import { lpaCashOfficeDetailsState } from '../../Recoil/LpaCashOfficeDetailsState';

import './feedbackPage.css';


const FeedbackPage = () => {
  const auditHeaderData = useRecoilValue(auditHeaderDataAtom);
  const auditCodeVale = useRecoilValue(auditCodeAtom);
  const managementScore = useRecoilValue(lpaManagementDetailsState);
  const fronlineScore = useRecoilValue(lpaFrontlineDetailsState);
  const securityScore = useRecoilValue(lpaSecurityDetailsState);
  const cashOfficeScore = useRecoilValue(lpaCashOfficeDetailsState);


  const frontlineScoreArray = Array.isArray(fronlineScore) ? fronlineScore : [];
  const filteredFrontlineDetails = frontlineScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  

  const securityScoreArray = Array.isArray(securityScore) ? securityScore : [];
  const filteredSecurityDetails = securityScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  

  const managementScoreArray = Array.isArray(managementScore) ? managementScore : [];
  const filteredManagementDetails = managementScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  


  
  const cashOfficeScoreArray = Array.isArray(cashOfficeScore) ? cashOfficeScore : [];
  const filteredCashOfficeDetails = cashOfficeScoreArray.filter(details => details.auditCode === auditCodeVale.auditCode);  
   
  

  return (
    <div className="feedbackContainer">
      
              
          
      {/* Render components based on the joined data */}
      <LpaFrontLineFeedback />
      <LpaSecurityFeedback />
      <LpaManagementFeedback />
      <LpaCashOfficeFeedback />

      <div className='tableFeedback'>
        <div className='firstColumn'>
          <div className='userRow'>
            {auditHeaderData[0]?.user}
          </div>
          <div className='dateRow'>
            Date: {auditHeaderData.dateTime}
          </div>
         
        </div>
        <div className='secondColumn'>
          <div className='headerRow'>
            <label className='headerTile'>Total LPA Score</label>
          </div>
         
          <div className='firstRow'>
            {filteredFrontlineDetails.length > 0 && (
                  <label className='rowData'>Frontline = {filteredFrontlineDetails[0].totalScore}%</label>
              )}
          </div>
          <div className='seondRow'>
            {filteredSecurityDetails.length > 0 && (
                  <label className='rowData'>Security = {filteredSecurityDetails[0].totalScore}%</label>
              )}
          </div>
           <div className='thirdRow'>
            {filteredManagementDetails.length > 0 && (
                <label className='rowData'>Receiving = {filteredManagementDetails[0].totalScore}%</label>
            )}
          </div>
          <div className='fourthRow'>
            {filteredCashOfficeDetails.length > 0 && (
                    <label className='rowData'>Cash Office = {filteredCashOfficeDetails[0].totalScore}%</label>
                )}
          </div>
          
        </div>
      </div>

    </div>
  );
};

export default FeedbackPage;
