import React, {useState} from 'react';
import { useRecoilState } from 'recoil';
import LpaCashOffice from './Pages/LPAStoreAnalysis/LPAStoreAnalysisCashOffice/LpaCashOffice';
import LpaFrontline from './Pages/LPAStoreAnalysis/LPAStoreAnalysisFrontline/LpaFrontLine';
import LpaSecurity from './Pages/LPAStoreAnalysis/LPAStoreAnalysisSecurity/LpaSecurity';
import LpaManagement from './Pages/LPAStoreAnalysis/LPAStoreAnalysisManagement/LpaManagement'
import './AuditerPage.css';

const AuditerPage = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  return (
    <div className="App">

      <LpaFrontline 
        isFormSubmitted={isFormSubmitted} 
        setIsFormSubmitted={setIsFormSubmitted}
       /> 
       <LpaSecurity 
        isFormSubmitted={isFormSubmitted} 
        setIsFormSubmitted={setIsFormSubmitted}
        />
       <LpaManagement 
        isFormSubmitted={isFormSubmitted} 
        setIsFormSubmitted={setIsFormSubmitted}
 />
      <LpaCashOffice 
        isFormSubmitted={isFormSubmitted} 
        setIsFormSubmitted={setIsFormSubmitted}/> 
     
    </div>
  );
}

export default AuditerPage;
