import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const lpaSecurityQuestionsState = atom({
  key: 'lpaSecurityQuestionsState',
  default: {
    LPAQuestions: [],
  },
  effects_UNSTABLE: [persistAtom],
});

