import React, {useState, useEffect} from "react";
import { RecoilRoot } from "recoil";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/login";
import AuditerPage from "./AuditerPage";
import Home from "./Pages/Home/Home";
import FeedbackPage from "./Pages/FeedbackPage/feedbackPage";
import FindingsLogs from "./Pages/FindingsLogs/findingsLogs";
import PastAuditFindingLogs from "./Pages/PastAuditFindingLogs/PastAuditFindingLogs";
import WebcamCapture from "./Pages/WebcamCapture/WebcamCapture";
import ErrorBoundary from "./Pages/ErrorBoundary/ErrorBoundary"
import { useNavigate } from "react-router-dom";

const RootComponent = () => {
  const navigate = useNavigate();
  const [isErrorBoundaryOpen, setisErrorBoundaryOpen] = useState(false)
const [errorPastAuditFindinglogsMessage, setErrorPastAuditFindinglogsMessage] = useState('')

  const onCloseErrorBoundary = () => {
    setisErrorBoundaryOpen(false)
    navigate('/home');
  }

  useEffect(() => {
    setErrorPastAuditFindinglogsMessage('No Records found or data corrupt');
  }, []);

  return (
     <RecoilRoot>
        <ErrorBoundary
         isOpen={isErrorBoundaryOpen}
         onClose={onCloseErrorBoundary}
         errorPastAuditFindinglogsMessage={errorPastAuditFindinglogsMessage}>

          <Routes>
          <Route path="/" element={<Login />} /> 
          <Route path="/home" element={<Home />} />
          <Route path="/auditerPage" element={<AuditerPage />} />
          <Route path="/feedbackPage" element={<FeedbackPage />} />
          <Route path="/findingsLogs" element={<FindingsLogs />} />
          <Route path="/pastAuditFindingLogs/:auditCode" element={<PastAuditFindingLogs />} />
          <Route path="/webcamCapture" element={<WebcamCapture />} />
        </Routes>
        </ErrorBoundary>
     </RecoilRoot>
  );
};

export default RootComponent;