import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import "../../../Styles/LpaStoreAnalysisStyles.css";
import { lpaManagementDetailsState } from '../../../Recoil/LpaManagementDetailsState'
import { auditCodeAtom } from '../../../Recoil/auditCodeAtom';

const LpaManagementFeedback = () => {
  const setLpaReceivingDetails = useSetRecoilState(lpaManagementDetailsState);
  const lpaReceivingDetails = useRecoilValue(lpaManagementDetailsState);
  const auditCodeValue = useRecoilValue(auditCodeAtom);

  const [isLoading, setIsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);
  
  useEffect(() => {
    // Fetch data from the API and update LPAQuestions in the atom
    const fetchData = async () => {
      try {
        const response = await axios.get('https://myworxit.co.za/api/audits/managementSectionTableDetails.php');
        const newData = response.data.records;
        // Update the LPAQuestions in the atom if newData is an array
        if (Array.isArray(newData)) {
          setLpaReceivingDetails(newData);
       
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData()
      .then(() => setIsDataReady(true)) // Set isDataReady to true when the data is available
      .catch(() => setIsDataReady(true)); // Also set isDataReady to true if there's an error to prevent an infinite loading state
  }, [setLpaReceivingDetails]);

  // Check if lpaAdminDetails is an array before using map()
  if (!Array.isArray(lpaReceivingDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

   // Filter lpaAdminDetails based on the auditCode
   const filteredReceivingDetails = lpaReceivingDetails.filter(details => details.auditCode === auditCodeValue.auditCode);
  
   if (!isDataReady) {
     return <div>Loading...</div>;
   }
 
   if (isLoading) {
     return <div>Loading...</div>;
   }

    return (
        <div className='questionContainer'>
            <div className='titleHeader'>
                <h4>LPA Store Analysis : Receiving</h4>
            </div>
            <table>
                <thead>
                    <tr>
                        {/* <th className="typeWidth">Type</th> */}
                        <th className="scoreTypeColumnWidth">Type</th>
                        <th className="answersColumnWidth">LPA Questions</th>
                        <th className="questionsColumnWidth">Observation/Responses</th>
                        <th className="notesColumnWidth">Notes </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredReceivingDetails.map((details, index) => (
                        <tr key={index}>
                        <td className="scoreTypeColumnWidth">
                            {details.scores === 'Pass' ? (
                                <div className='passCircle'></div>
                            ) : details.scores === 'Fail' ? (
                                <div className='failCircle'></div>
                            ) : (
                                <div className='naCircle'></div>
                            )}
                        </td>
                        <td className="questionsColumnWidth">{details.question}</td>
                        <td className="answersColumnWidth">{details.responses} </td>
                        <td className="notesColumnWidth">{details.notes} </td>
                        </tr>
                    ))}
                    </tbody>
            </table>  
            <div className='titleFooter'>
                {filteredReceivingDetails.length > 0 && (
                    <h4>Receiving LPA Score = {filteredReceivingDetails[0].totalScore}%</h4>
                )}
            </div>
        </div>
   
    )
}

export default LpaManagementFeedback;