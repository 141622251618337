import React, { useEffect, useState} from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState,useRecoilState } from 'recoil';
import {  useNavigate } from "react-router-dom";
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { auditHeaderDataAtom } from '../../Recoil/auditHeaderDataAtom';
import { auditCodeAtom } from '../../Recoil/auditCodeAtom';

import { lpaFrontlineQuestionsState } from '../../Recoil/LpaFrontlineQuestionsState';
import { lpaFrontlineDataState } from '../../Recoil/LpaFrontLineDataState';
import { lpaFrontlineDetailsState } from '../../Recoil/LpaFrontlineDetailsState';

import { lpaSecurityQuestionsState } from '../../Recoil/LpaSecurityQuestionsState';
import { lpaSecurityDataState } from '../../Recoil/LpaSecurityDataState';
import { lpaSecurityDetailsState } from '../../Recoil/LpaSecurityDetailsState';

import { lpaManagementQuestionsState } from '../../Recoil/LpaManagementQuestionsState';
import { lpaManagementDataState } from '../../Recoil/LpaManagementDataState'
import { lpaManagementDetailsState } from '../../Recoil/LpaManagementDetailsState';

import { lpaCashOfficeQuestionsState } from '../../Recoil/LpaCashOfficeQuestionsState';
import { lpaCashOfficeDataState } from '../../Recoil/LpaCashOfficeDataState';
import { lpaCashOfficeDetailsState } from '../../Recoil/LpaCashOfficeDetailsState';

import '../../Styles/LpaStoreAnalysisStyles.css'

const FindingsLogs = () => {

    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const auditHeaderData = useRecoilValue(auditHeaderDataAtom);
    const auditCodeValue = useRecoilValue(auditCodeAtom);
    const setAuditCodeValue = useSetRecoilState(auditCodeAtom);
    const [isAllActionsFilled, setIsAllActionsFilled] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Add isLoading state
    
   //------------TEST --------------- 
    const lpaFrontlineData = useRecoilValue(lpaFrontlineDataState)
    const lpaFrontlineQuestions = useRecoilValue(lpaFrontlineQuestionsState);

    const lpaSecurityData = useRecoilValue(lpaSecurityDataState);
    const lpaSecurityQuestions = useRecoilValue(lpaSecurityQuestionsState);

    const lpaManagementData = useRecoilValue(lpaManagementDataState);
    const lpaManagementQuestions = useRecoilValue(lpaManagementQuestionsState);

    const lpaCashOfficeData = useRecoilValue(lpaCashOfficeDataState);
    const lpaCashOfficeQuestions = useRecoilValue(lpaCashOfficeQuestionsState);

    const resetAuditCode = useResetRecoilState(auditCodeAtom)

    const resetLpaFrontlineDetailsState = useResetRecoilState(lpaFrontlineDetailsState)
    const resetlpaFrontlineDataState = useResetRecoilState(lpaFrontlineDataState)
    
    const resetLpaSecurityDetailsState = useResetRecoilState(lpaSecurityDetailsState)
    const resetLpaSecurityDataState = useResetRecoilState(lpaSecurityDataState)
    
    const resetLpaManagementDetailsState = useResetRecoilState(lpaManagementDetailsState)
    const resetLpaManagementDataState = useResetRecoilState(lpaManagementDataState)
    
    const resetLpaCashOfficeDetailsState= useResetRecoilState(lpaCashOfficeDetailsState)
    const resetLpaCashOfficeDataState = useResetRecoilState(lpaCashOfficeDataState)

    
    useEffect(() => {
        // Remove persisted data from local storage when the component mounts
        localStorage.removeItem('auditCodeAtom');

        localStorage.removeItem('lpaCashOfficeDataState');
        localStorage.removeItem('lpaCashOfficeDetailsState');

        localStorage.removeItem('lpaFrontlineDataState');
        localStorage.removeItem('lpaFrontlineDetailsState');

        localStorage.removeItem('lpaManagementDataState');
        localStorage.removeItem('lpaManagementDetailsState');

        localStorage.removeItem('lpaSecurityDataState');
        localStorage.removeItem('lpaSecurityDetailsState');
      }, []);

    const combinedQuestions = {
        LPAQuestions: [
            ...lpaFrontlineQuestions.LPAQuestions,
            ...lpaSecurityQuestions.LPAQuestions,
            ...lpaManagementQuestions.LPAQuestions,
            ...lpaCashOfficeQuestions.LPAQuestions
        ],
    };

    const combinedData = [
        ...lpaFrontlineData.responses.map((response, index) => ({
            quesId: lpaFrontlineData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaFrontlineData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaFrontlineData.scores[index],
            capturedImages: lpaFrontlineData.capturedImages[index],
          })),
        ...lpaSecurityData.responses.map((response, index) => ({
            quesId: lpaSecurityData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaSecurityData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaSecurityData.scores[index],
            capturedImages: lpaSecurityData.capturedImages[index],
          })),
        ...lpaManagementData.responses.map((response, index) => ({
            quesId: lpaManagementData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaManagementData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaManagementData.scores[index],
            capturedImages: lpaManagementData.capturedImages[index],
          })),
        ...lpaCashOfficeData.responses.map((response, index) => ({
            quesId: lpaCashOfficeData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaCashOfficeData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaCashOfficeData.scores[index],
            capturedImages: lpaCashOfficeData.capturedImages[index],
          })),
      ];
      
      
    const filteredDataDetails = combinedData.filter(details => details.scores === 'Fail');
    console.log("filteredDataDetails: ",filteredDataDetails)
    const someLength = filteredDataDetails.length;

    const initialData = {
      creationDate: "",
      responses: "",
      actions: Array(someLength).fill(""), // Initialize with empty strings
      responsPerson: Array(someLength).fill(""),
      dueDate: Array(someLength).fill(""),
    };

    const frontlineScore = lpaFrontlineData.scores.map((index) => ({
        scores: lpaFrontlineData.totalScore,
    }));
    console.log('frontlineScore', frontlineScore)

    const securityScore = lpaSecurityData.scores.map((index) => ({
        scores: lpaSecurityData.totalScore,
    }));
    
    const managementScore = lpaManagementData.scores.map((index) => ({
        scores: lpaManagementData.totalScore,
    }));

    const cashOfficeScore = lpaCashOfficeData.scores.map((index) => ({
        scores: lpaCashOfficeData.totalScore,
    }));
    
    
    const frontlineTotoalScore = frontlineScore.length > 0 ? parseInt(frontlineScore[0].scores) : 0;
    const securityTotolScore = securityScore.length > 0 ? parseInt(securityScore[0].scores) : 0;
    const managementTotalScore = managementScore.length > 0 ? parseInt(managementScore[0].scores) : 0;
    const cashOfficeTotoalScore = cashOfficeScore.length > 0 ? parseInt(cashOfficeScore[0].scores) : 0;


    let numberOfScores = 0;
    if (frontlineTotoalScore !== 0) numberOfScores++;
    if (securityTotolScore !== 0) numberOfScores++;
    if (managementTotalScore !== 0) numberOfScores++;
    if (cashOfficeTotoalScore !== 0) numberOfScores++;

    const numberTotalOfScores = numberOfScores * 100
    console.log(numberOfScores)
    console.log("frontlineTotoalScore",frontlineTotoalScore)
    console.log("securityTotolScore",securityTotolScore)
    console.log("managementTotalScore",managementTotalScore)
    console.log("cashOfficeTotoalScore",cashOfficeTotoalScore)

    const totolScorePercentage = (frontlineTotoalScore + securityTotolScore + managementTotalScore + cashOfficeTotoalScore)
    console.log("totolScorePercentage",totolScorePercentage)

    let scoreCard = (totolScorePercentage / numberTotalOfScores) * 100;
    scoreCard = Math.ceil(scoreCard);
    console.log("scoreCard", scoreCard)
    

    const [findingsData, setFindingsData] = useState(initialData);
   
    useEffect(() => {
      // Check if all actions are filled
      const areActionsFilled =  findingsData.actions.every(action => action.trim() !== "" || 0) && 
                                findingsData.responsPerson.every(action => action.trim() !== "" || 0) &&
                                findingsData.dueDate.every(action => action.trim() !== "" || 0);
      setIsAllActionsFilled(areActionsFilled);
    }, [findingsData.actions, findingsData.responsPerson, findingsData.dueDate]);

    console.log("Data Actions: ", findingsData.actions)
            
    const clearState = () => {
        resetAuditCode()

        resetLpaCashOfficeDetailsState();
        resetLpaCashOfficeDataState();

        resetLpaFrontlineDetailsState();
        resetlpaFrontlineDataState();

        resetLpaManagementDetailsState();
        resetLpaManagementDataState();

        resetLpaSecurityDetailsState();
        resetLpaSecurityDataState();
    }

        
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading state to true
    
        const combinedQuestions = filteredDataDetails.map((details) => details.question);
        const combinedResponses = filteredDataDetails.map((details) => details.responses);
        const combinedCapturedImages = filteredDataDetails.map((details) => details.capturedImages);
    
        const auditCode = auditCodeValue.auditCode;
        const user = auditHeaderData[0]?.user;
        const site = auditHeaderData[0]?.site;
        const longitude = auditHeaderData[0]?.longitude;
        const latitude = auditHeaderData[0]?.latitude;
    
        const sendData = {
            auditCode: auditCode,
            questions: combinedQuestions,
            responses: combinedResponses,
            actions: findingsData.actions,
            responsPerson: findingsData.responsPerson,
            dueDate: findingsData.dueDate,
            capturedImages: combinedCapturedImages,
        };
    
        const sendAuditLogs = {
            auditCode: auditCode,
            user: user,
            site: site,
            averagePercentage: scoreCard,
            longitude: longitude,
            latitude: latitude,
        };
    
        console.log(sendData);
        console.log(sendAuditLogs);
    
        const date = new Date().toLocaleString('en-US', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    
        setAuditCodeValue((prevLpaData) => ({
            ...prevLpaData,
            date,
        }));
    
        const frontlineQuestionsId = lpaFrontlineQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        const frontlineQuestions = lpaFrontlineQuestions.LPAQuestions.map((questionObj) => questionObj.questions);
    
        const securityQuestionsId = lpaSecurityQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        const securityQuestions = lpaSecurityQuestions.LPAQuestions.map((questionObj) => questionObj.questions);
    
        const managementQuestionsId = lpaManagementQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        const managementQuestions = lpaManagementQuestions.LPAQuestions.map((questionObj) => questionObj.questions);
    
        const cashOfficeQuestionsId = lpaCashOfficeQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
        const cashOfficeQuestions = lpaCashOfficeQuestions.LPAQuestions.map((questionObj) => questionObj.questions);
    
        const promises = [
            axios.post("https://myworxit.co.za/api/audits/findingLogs.php", sendData),
            axios.post("https://myworxit.co.za/api/audits/auditLogs.php", sendAuditLogs),
            axios.post("https://myworxit.co.za/api/audits/frontlineSectionData.php", {
                auditCode: lpaFrontlineData.auditCode,
                section: lpaFrontlineData.section,
                quesId: frontlineQuestionsId,
                question: frontlineQuestions,
                responses: lpaFrontlineData.responses,
                notes: lpaFrontlineData.notes,
                scores: lpaFrontlineData.scores,
                capturedImages: lpaFrontlineData.capturedImages,
                totalScore: lpaFrontlineData.totalScore,
            }),
            axios.post("https://myworxit.co.za/api/audits/securitySectionData.php", {
                auditCode: lpaSecurityData.auditCode,
                section: lpaSecurityData.section,
                quesId: securityQuestionsId,
                question: securityQuestions,
                responses: lpaSecurityData.responses,
                notes: lpaSecurityData.notes,
                scores: lpaSecurityData.scores,
                capturedImages: lpaSecurityData.capturedImages,
                totalScore: lpaSecurityData.totalScore,
            }),
            axios.post("https://myworxit.co.za/api/audits/managementSectionData.php", {
                auditCode: lpaManagementData.auditCode,
                section: lpaManagementData.section,
                quesId: managementQuestionsId,
                question: managementQuestions,
                responses: lpaManagementData.responses,
                notes: lpaManagementData.notes,
                scores: lpaManagementData.scores,
                capturedImages: lpaManagementData.capturedImages,
                totalScore: lpaManagementData.totalScore,
            }),
            axios.post("https://myworxit.co.za/api/audits/cashOfficeSectionData.php", {
                auditCode: lpaCashOfficeData.auditCode,
                section: lpaCashOfficeData.section,
                quesId: cashOfficeQuestionsId,
                question: cashOfficeQuestions,
                responses: lpaCashOfficeData.responses,
                notes: lpaCashOfficeData.notes,
                scores: lpaCashOfficeData.scores,
                capturedImages: lpaCashOfficeData.capturedImages,
                totalScore: lpaCashOfficeData.totalScore,
            })
        ];
    
        Promise.all(promises)
            .then((res) => {
                setErrorMessage("");
                setFindingsData({ responses: "", actions: "", responsPerson: "", dueDate: "" });
                navigate('/home');
                clearState();
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage("An error occurred.");
            })
            .finally(() => {
                setIsLoading(false); // Set loading state to false
            });
    };
    
   
  return (
    <div className="findingLogsContainer">
         <div className='titleHeader'>
                <h4>Alert</h4>
            </div>
       <table>
            <thead>
                <tr>
                    <th className="typeColWidth">Type</th>
                    <th className="responsesColWidth">Responses</th>
                    <th className="imageColWidth">Capured Image</th>
                    <th className="actionColWidth">Action</th>
                    <th className="responPersColWidth">Responsible Person</th>
                    <th className="dueDateColWidth">Due Date</th>

                </tr>
            </thead>
            <tbody>
            {filteredDataDetails && filteredDataDetails.length > 0 ? (
            filteredDataDetails
                //.filter(details => details.scores === 'Fail')
                .map((details, index) => (
                <tr key={index}>
                    <td className="typeColWidth">
                    <div className='failCircle'></div>
                    </td>
                    <td className="responsesColWidth">
                    <div>{details.question} - {details.responses}</div>
                    </td>
                    <td className="imageColWidth">
                    {details.capturedImages ? (
                        <div className='capturedImageCont'>
                        <a key={index} href={details.capturedImages} target='_blank' rel='noopener noreferrer'>
                    <img src={details.capturedImages} alt='captured' width='50px' />
                  </a>
                        </div>
                    ) : (
                        <div className='capturedImageCont'>
                        <p>No Image Captured</p>
                        </div>
                    )}
                    </td>
                    <td className="actionColWidth">
                        <div className='actionColCont'>
                            <input
                            className='actionColInput'
                                type= "text"
                                name="actions"
                                variant="outlined"
                                required
                                value={findingsData.actions[index]}
                                onChange={(e) => {
                                    const newActions = [...findingsData.actions]; // Create a copy of the actions array
                                    newActions[index] = e.target.value; // Update the value at the current index
                                    setFindingsData({ ...findingsData, actions: newActions }); // Update the state with the new array
                                }}
                            />
                        </div>
                        
                    </td>
                    <td className="responPersColWidth">
                        <div className='responPersColCont'>
                        <input
                        className='responPersColInput'
                            type= "text"
                            name="responsPerson"
                            variant="outlined"
                            required
                            value={findingsData.responsPerson[index]}
                            onChange={(e) => {
                                const newResponsPerson = [...findingsData.responsPerson]; // Create a copy of the actions array
                                newResponsPerson[index] = e.target.value; // Update the value at the current index
                                setFindingsData({ ...findingsData, responsPerson: newResponsPerson }); // Update the state with the new array
                            }}					
                        />
                        </div>
                    </td>
                    <td className="dueDateColWidth">
                        <div className='dueDateColCont'>

                       
                    <input
                    className='dueDateColInput'
                            type= "date"
                            name="dueDate"
                            variant="outlined"
                            required
                            value={findingsData.dueDate[index]}
                            onChange={(e) => {
                                const newDueDates = [...findingsData.dueDate]; // Create a copy of the dueDate array
                                newDueDates[index] = e.target.value; // Update the value at the current index
                                setFindingsData({ ...findingsData, dueDate: newDueDates }); // Update the state with the new array
                            }}				
                        />
                         </div>
                    </td>
                   
                </tr>
            ))) : (
                <tr>
                <td colSpan="1" className='noFindingsText'>No findings found for the provided audit code.</td>
            </tr>
            )}
            </tbody>
        </table>
        <div className='submitAuditContainer'>
        {isLoading ? 
        <ClipLoader size={20} color={"#123abc"} loading={isLoading} /> : 
        <button className={`auditBtn ${!isAllActionsFilled ? 'disabled' : ''}`} onClick={handleSubmit} disabled={!isAllActionsFilled}>Submit Audit for Completion</button>

        }

        </div>
    </div>
  );
};

export default FindingsLogs;
