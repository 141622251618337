import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import '../../Styles/LpaStoreAnalysisStyles.css'
import './Home.css'
import { auditHeaderDataAtom } from '../../Recoil/auditHeaderDataAtom';
import { auditCodeAtom } from '../../Recoil/auditCodeAtom';
import { lpaFrontlineQuestionsState } from '../../Recoil/LpaFrontlineQuestionsState';
import { lpaFrontlineDataState } from '../../Recoil/LpaFrontLineDataState';
import { lpaSecurityQuestionsState } from '../../Recoil/LpaSecurityQuestionsState';
import { lpaSecurityDataState } from '../../Recoil/LpaSecurityDataState';
import { lpaManagementQuestionsState } from '../../Recoil/LpaManagementQuestionsState';
import { lpaManagementDataState } from '../../Recoil/LpaManagementDataState';
import { lpaCashOfficeQuestionsState } from '../../Recoil/LpaCashOfficeQuestionsState';
import { lpaCashOfficeDataState } from '../../Recoil/LpaCashOfficeDataState';
import { auditLogsAtom } from '../../Recoil/auditlogsAtom';
import ReactPaginate from 'react-paginate';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import ClipLoader from "react-spinners/ClipLoader";

const Home = () => {
  const auditCodeData = useRecoilValue(auditHeaderDataAtom);
  const setAuditHeaderData = useSetRecoilState(auditHeaderDataAtom)
  const auditCodeValue = useRecoilValue(auditCodeAtom);
  const setAuditCodeValue = useSetRecoilState(auditCodeAtom);
  const lpaDataCashOffice = useRecoilValue(lpaCashOfficeDataState);
  const lpaDataFrontline = useRecoilValue(lpaFrontlineDataState);
  const lpaDataManagement = useRecoilValue(lpaManagementDataState);
  const lpaDataSecurity = useRecoilValue(lpaSecurityDataState);

  const lpaFrontlineQuestions = useRecoilValue(lpaFrontlineQuestionsState);
  const lpaSecurityQuestions = useRecoilValue(lpaSecurityQuestionsState);
  const lpaManagementQuestions = useRecoilValue(lpaManagementQuestionsState);
  const lpaCashOfficeQuestions = useRecoilValue(lpaCashOfficeQuestionsState);
  const lpaFrontlineData = useRecoilValue(lpaFrontlineDataState);
  const lpaSecurityData = useRecoilValue(lpaSecurityDataState);
  const lpaManagementData = useRecoilValue(lpaManagementDataState);
  const lpaCashOfficeData = useRecoilValue(lpaCashOfficeDataState);

  const [auditLogs, setAuditLogs] = useRecoilState(auditLogsAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [searchSite, setSearchSite] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [pageNumber, setPageNumber] = useState(0);
  const reportsPerPage = 10; // Set the number of reports to display per page
  const pagesVisited = pageNumber * reportsPerPage;

  const resetAuditCodeAtom = useResetRecoilState(auditCodeAtom);
  const resetAuditHeaderDataAtom = useResetRecoilState(auditHeaderDataAtom);
  const resetAuditLogsAtom = useResetRecoilState(auditLogsAtom);
  const resetLpaFrontlineQuestions = useResetRecoilState(lpaFrontlineQuestionsState);
  const resetLpaFrontlineData = useResetRecoilState(lpaFrontlineDataState);
  const resetLpaSecurityQuestions = useResetRecoilState(lpaSecurityQuestionsState);
  const resetLpaSecurityData = useResetRecoilState(lpaSecurityDataState);
  const resetLpaManagementQuestions = useResetRecoilState(lpaManagementQuestionsState);
  const resetLpaManagementData = useResetRecoilState(lpaManagementDataState);
  const resetLpaCashOfficeQuestions = useResetRecoilState(lpaCashOfficeQuestionsState);
  const resetLpaCashOfficeData = useResetRecoilState(lpaCashOfficeDataState);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [viewSites, setViewSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');

  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    localStorage.removeItem('auditHeaderDataAtom');
    localStorage.removeItem('auditLogsAtom');
    localStorage.removeItem('auditCodeAtom');

    localStorage.removeItem('lpaCashOfficeQuestionsState');
    localStorage.removeItem('lpaCashOfficeDataState');

    localStorage.removeItem('lpaFrontlineQuestionsState');
    localStorage.removeItem('lpaFrontlineDataState');

    localStorage.removeItem('lpaManagementQuestionsState');
    localStorage.removeItem('lpaManagementDataState');

    localStorage.removeItem('lpaSecurityQuestionsState');
    localStorage.removeItem('lpaSecurityDataState');

  }, []);

  const generateRandomCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }
    return code;
  };

  const navigate = useNavigate();


  const { format } = require('date-fns');


  const handleSearch = async () => {
    let fromDate = '';
    let toDate = '';

    if (!dateRange || !dateRange[0]) {
      // If no date range is specified, fetch reports for the last 30 days
      fromDate = 'yyyy-MM-dd 00:00:00';
      toDate = format(new Date(), 'yyyy-MM-dd 23:59:59');
    } else {
      fromDate = format(dateRange[0], 'yyyy-MM-dd 00:00:00');
      toDate = format(dateRange[1], 'yyyy-MM-dd 23:59:59');
    }

    try {
      const response = await axios.get(`https://myworxit.co.za/api/audits/viewAuditLogs.php`, {
        params: {
          create_from: fromDate,
          create_to: toDate,
          searchSite: searchSite,
          searchValue: searchValue,
        },
      });

      console.log("API Response: ", response.data);

      const newData = response.data.records;
      setAuditLogs(newData);
      setIsLoading(false);
      setSearchClicked(true);
      setPageNumber(0);

    } catch (error) {
      console.error("Error fetching data: ", error);
      setIsLoading(false);
    }
  };



  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleContinue = () => {

    // Check if geolocation is available in the browser
    if ('geolocation' in navigator) {
      // Use the Geolocation API to get the user's current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          //Extract latitude and longitude from the position object
          const { latitude, longitude } = position.coords;


          setAuditCodeValue((prevLpaData) => ({
            ...prevLpaData,
            auditCode: generateRandomCode(),
          }));

          setAuditHeaderData(oldHeaderData => oldHeaderData.map((header) => ({
            ...header,
            site: selectedSite,
            latitude,
            longitude
          })));


          // Ensure each property is properly initialized as an empty array
          if (!Array.isArray(lpaFrontlineData.responses)) {
            lpaFrontlineData.responses = [];
          }
          if (!Array.isArray(lpaFrontlineData.notes)) {
            lpaFrontlineData.notes = [];
          }
          if (!Array.isArray(lpaFrontlineData.scores)) {
            lpaFrontlineData.scores = [];
          }

          if (lpaFrontlineData.responses.length === 0 && lpaFrontlineData.notes.length === 0 &&
            lpaFrontlineData.scores.length === 0) {
            setModalIsOpen(false);
            navigate('/auditerPage');
          } else {
            alert("Please complete the current audit before starting a new one");
          }
        },
        (error) => {
          console.error('Error getting location:', error);
          alert("Please enable location for browser")
          setIsLoading(false); // Set loading state to false if geolocation fails
        }
      );
    } else {
      // Geolocation is not available in the browser, handle accordingly
      console.error('Geolocation is not available in this browser.');
      // Rest of the function logic (without latitude and longitude handling)
      setIsLoading(false); // Set loading state to false if geolocation is not available
    }
  };

  const resetData = () => {
    setSearchSite('');
    setSearchValue('');
    setDateRange([null, null]);
    setSearchClicked(false);
    setPageNumber(0);
    setIsSearchDropdownOpen(false)
  }

  useEffect(() => {
    // Fetch sites data from the API
    const fetchSites = async () => {
      try {
        const response = await axios.get('https://myworxit.co.za/api/audits/viewSites.php'); // Replace with your API endpoint
        const sitesData = response.data.records;
        setViewSites(sitesData);
      } catch (error) {
        console.error('Error fetching sites:', error);
      }
    };

    fetchSites();
  }, []);

  const allAtomsAreFullyFilled = () => {
    if (!lpaDataCashOffice || !lpaDataFrontline || !lpaDataManagement || !lpaDataSecurity) return false; // If either lpaDataAdmin or lpaDataBackups is empty, return false
    const { scores: cashOfficeScores } = lpaDataCashOffice;
    const { scores: frontlineScores } = lpaDataFrontline;
    const { scores: managementScores } = lpaDataManagement;
    const { scores: securityScores } = lpaDataSecurity;
    return (
      
      cashOfficeScores.length === lpaCashOfficeQuestions.LPAQuestions.length
      &&
     
      frontlineScores.length === lpaFrontlineQuestions.LPAQuestions.length
      &&
     
      managementScores.length === lpaManagementQuestions.LPAQuestions.length
      &&
      
      securityScores.length === lpaSecurityQuestions.LPAQuestions.length

    );

  };

  const anyAtomHasData = () => {
    const lpaDataAtoms = [
      lpaDataCashOffice,
      lpaDataFrontline,
      lpaDataManagement,
      lpaDataSecurity,
    ];

    return lpaDataAtoms.some(data => {
      if (!data) return false; // If the data is empty, return false
      const { responses, scores } = data;
      return responses.length > 0 || scores.length > 0;
    });
  };

  const getLatestDate = () => {
    const dates = [
      lpaDataCashOffice.dateTime,
      lpaDataFrontline.dateTime,
      lpaDataManagement.dateTime,
      lpaDataSecurity.dateTime,
    ];

    const validDates = dates.filter(date => date).map(date => new Date(date.replace(' at ', ' ')));
    const latestDate = new Date(Math.max(...validDates));
    const formattedLatestDate = latestDate.toLocaleString('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
    return formattedLatestDate;
  };

  const latestFormattedDate = getLatestDate();

 const handleClick = () => {
  setIsLoading(true);
  
  const dateTime = new Date().toLocaleString('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  setAuditCodeValue((prevLpaData) => ({
    ...prevLpaData,
    dateTime,
  }));

  setTimeout(() => {
    navigate('/findingsLogs');
    setIsLoading(false);
  }, 1000); // Adjust the delay as needed
  
};


  const handleLogout = () => {
    resetAuditCodeAtom();
    resetAuditHeaderDataAtom();
    resetAuditLogsAtom();
    resetLpaCashOfficeQuestions();
    resetLpaFrontlineQuestions();
    resetLpaManagementQuestions();
    resetLpaSecurityQuestions();

    resetLpaFrontlineData();
    resetLpaCashOfficeData();
    resetLpaManagementData();
    resetLpaSecurityData();

    navigate('/');
  };

  const parseDateString = (dateString) => {
    const parts = dateString.split(' at ');
    const formattedDate = parts[0].replace('Friday, ', '').replace(/(st|nd|rd|th),/, ',') + ' ' + parts[1];
    return new Date(formattedDate);
  };

  const pageCount = Math.ceil(auditLogs.length / reportsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


  const handleSearchInputChange = (e) => {
    setSearchSite(e.target.value);
    setIsSearchDropdownOpen(true);

  };

  const handleInputChange = (e) => {
    setSelectedSite(e.target.value)
    setIsDropdownOpen(true)

  };

  const handleSearchOptionClick = (site) => {
    setSearchSite(site);
    setIsSearchDropdownOpen(false);
  };

  const handleOptionClick = (site) => {
    setSelectedSite(site)
    setIsDropdownOpen(false)
  };

  const filteredSearchSites = viewSites.filter((item) =>
    item.site.toLowerCase().includes(searchSite.toLowerCase())
  );

  const filteredSites = viewSites.filter((item) =>
    item.site.toLowerCase().includes(selectedSite.toLowerCase())
  );

  const onModalClose = () => {
    setModalIsOpen(false);
    setSelectedSite('');
    setIsDropdownOpen(false)
  }

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  const HandlePastAuditFindingLogsClick = (auditCode) => {
    navigate(`/pastAuditFindingLogs/${auditCode}`);
  }

  return (
    <div className='conatinerBackground'>
      <div className='auditLogoutConatiner'>
        <div className='auditBtnCont'>
          <button className='auditBtn' onClick={handleModalOpen}>Start New Audit</button>
        </div>
        <div className='logoutBtnCont'>
          <button className='logoutBtn' onClick={handleLogout}>LogOut</button>
        </div>
      </div>


      <div className='progressContainer'>
        {anyAtomHasData() ? (
          allAtomsAreFullyFilled() ? (
            <table className='progresstbl'>
              <thead>
                <tr>
                  <th className="DateColWidth">Start Date</th>
                  <th className="siteNoColWidth">Site No</th>
                  <th className="ProgessColWidth">Progress</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="DateColWidth">{latestFormattedDate}</td>
                  <td className="siteNoColWidth">{auditCodeData[0]?.site}</td>
                  <td className="ProgessColWidth">
                  {isLoading ? (
                      <ClipLoader color={"#123abc"} loading={isLoading} size={35} />
                    ) : (
                      <button className="btn btn-success" onClick={handleClick}>Findings</button>

                    )}
                    </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className='progresstbl'>
              <thead>
                <tr>
                  <th className="DateColWidth">Start Date</th>
                  <th className="siteNoColWidth">Site No</th>
                  <th className="ProgessColWidth">Progress</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="DateColWidth">{latestFormattedDate}</td>
                  <td className="siteNoColWidth">{auditCodeData[0]?.site}</td>
                  <td className="ProgessColWidth"><Link className="busyWithAuditBtn" to={'/auditerPage'}>Busy with Audit</Link></td>
                </tr>
              </tbody>
            </table>
          )
        ) : (
          <div>No audits</div>
        )}
      </div>
      <div className='searchCont'>
        <div className='searchTitleColumn'>
          <label className='searchTitle'>Search</label>
        </div>
        <div className='searchColumn'>
          <div className='searchDateCont'>
            <div className='searchNameCont'>
              <label className='searchName'>Date:</label>
            </div>
            <div className='searchDate'>
              <DateRangePicker
                className='dateInput'
                value={dateRange}
                onChange={(value) => {
                  setDateRange(value);
                }}
                onClean={resetData}
                placeholder={"Search Date Range"}
              />
            </div>
          </div>
          <div className='searchReportNameCont'>
            <div className='searchNameCont'>
              <label className='searchName' htmlFor="searchDropdown">Site:</label>
            </div>
            <div className='dropdownSearchCont'>
              <input
                className='selectSiteInput'
                type="text"
                value={searchSite}
                onChange={handleSearchInputChange}
                onFocus={() => setIsSearchDropdownOpen(true)}
                placeholder="Search site..."
              />
              {isSearchDropdownOpen && (
                <div className="searchDropdown">
                  {filteredSearchSites.length > 0 ? (
                    filteredSearchSites.map((item) => (
                      <div
                        key={item.id}
                        className="dropdown-item"
                        onClick={() => handleSearchOptionClick(item.site)}
                      >
                        {item.site}
                      </div>
                    ))
                  ) : (
                    <div className="dropdown-item">No results found</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='searchAnythingCont'>
            <div className='searchNameCont'>
              <label className='searchName'>Find</label>
            </div>
            <div className='searchAnything'>
              <input
                className='inputSearchAnythingComponent'
                type="text"
                placeholder='Search for anything'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='searchBtnStockControlCont'>
          <button className='clearBtn' onClick={resetData}>Clear</button>
          <button className='searchBtn' onClick={handleSearch}>Search</button>
        </div>
      </div>

      <div className='passedTableTitle'>
        <h4 className='passedTitleLbl'>Past Audits</h4>
      </div>
      <div className='passedTable'>
        {searchClicked && auditLogs.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  <th className='compByColumnWidth'>Completed By</th>
                  <th className='siteColumnWidth'>Site</th>
                  <th className='dateColumnWidth'>Completion Date</th>
                  <th className='averageColumnWidth'>Ave %</th>
                  <th className='latitudeColumnWidth'>Co Ordinates</th>
                {/* <th className='longitudeColumnWidth'>Longitude</th> */}
                </tr>
              </thead>
              <tbody>

                {auditLogs
                  .sort((a, b) => parseDateString(b.completionDate) - parseDateString(a.completionDate))
                  .slice(pagesVisited, pagesVisited + reportsPerPage)
                  .map((details, index) => (
                    <tr key={index} onClick={() => HandlePastAuditFindingLogsClick(details.auditCode)}>
                      <td className='compByColumnWidth'>{details.user}</td>
                      <td className='siteColumnWidth'>{details.site}</td>
                      <td className='dateColumnWidth'>{details.completionDate}</td>
                      <td className='averageColumnWidth'>{details.averagePercentage}%</td>
                      <td className='latitudeColumnWidth'>{details.latitude} {details.longitude}</td> 
                      {/* <td className='longitudeColumnWidth'>{details.longitude}</td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={'<<'}
              nextLabel={'>>'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </>
        ) : searchClicked ? (
          <div className='noRecordFoundCont'>
            <label className='searchName'> No records found</label>
          </div>
        ) : null}

      </div>



      <Modal open={modalIsOpen} onClose={onModalClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={boxStyle}>
          <h2 id="modal-title">Select Site</h2>
          <div className='modalContent'>
            <div className='siteTitleCont'>
              <label htmlFor="site-select">Site:</label>
            </div>
            <div className='dropdownSearchCont'>
              <input
                className='selectSiteInput'
                type="text"
                value={selectedSite}
                onChange={handleInputChange}
                onFocus={() => setIsDropdownOpen(true)}
                placeholder="Search site..."
              />
              {isDropdownOpen && (
                <div className="dropdown">
                  {filteredSites.length > 0 ? (
                    filteredSites.map((item) => (
                      <div
                        key={item.id}
                        className="dropdown-item"
                        onClick={() => handleOptionClick(item.site)}
                      >
                        {item.site}
                      </div>
                    ))
                  ) : (
                    <div className="dropdown-item">No results found</div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='modalActionBtns'>
            <div className='continueBtnModalCont'>
              <button className='continueBtnModal' onClick={handleContinue} disabled={!selectedSite}>Continue</button>
            </div>
            <div className='cancelBtnModalCont'>
              <button className='cancelBtnModal' onClick={onModalClose}>Cancel</button>
            </div>
          </div>

        </Box>
      </Modal>
    </div>
  );
};

export default Home;
