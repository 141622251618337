import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()


export const lpaFrontlineDetailsState = atom({
  key: 'lpaFrontlineDetailsState',
  default: {
    LPAQuestions: [],
    responses: [],
    totalScore: []
  },
  effects_UNSTABLE: [persistAtom],
});