import React from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import './WebcamCapture.css'; // Import the CSS file
import { Modal, Box, Button } from '@mui/material';

const WebcamCapture = ({ isOpen, onClose, onTakePhoto }) => {
  const handleTakePhoto = (dataUri) => {
    onTakePhoto(dataUri);
  };

  function handleCameraError (error) {
    alert('handleCameraError', error)
    console.log('handleCameraError', error);
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className="webcam-ModalContent" >
        <div className="webcamContainer">
          <Camera
            idealResolution={{ width: 1920, height: 1080 }} // Lower resolution example
            //isMaxResolution={false}
            sizeFactor={0.3}
            onTakePhoto={handleTakePhoto}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isFullscreen={true}
            onCameraError = { (error) => { handleCameraError(error); } }
            isDisplayStartCameraError = {true}
          />
        </div>
        <button className='closeCaptureModal' onClick={onClose}>Close</button>
      </Box>
    </Modal>
  );
};

export default WebcamCapture;